export class NotificationsFakeData {
  public static data = {
    systemMessages: [
      {
        icon: 'alert-triangle',
        heading: '<span class="font-weight-bolder">High memory</span> usage',
        text: 'BLR Server using high memory'
      }
    ],
    system: true
  };
}
