import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { interval } from 'rxjs';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public timer = interval(1800000);
  public notifications: notification;
  public lengthNotification:any;
  
  public idNotification:any;
  
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this._notificationsService.getNotificationsData().subscribe(res => {
    //   if(res) {
    //     this.idNotification = res.data[0].id;
    //     this.notifications = res.data;
    //     this.lengthNotification = Object.keys(res.data).length;
    //   }
    // });
    // this.countTimer();
  }


  updateNotification() {
    this._notificationsService.updateNotificationsData(this.idNotification).subscribe({
      next: (res) => {
        this.ngOnInit();
      },
      error: (e) => console.error(e)
    });
  }

  countTimer() {
    this.timer.subscribe((d)=> {
      console.log(d);
    });
  }

}