import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  token = localStorage.getItem('currentUser');
  
  // Cabeceras necesarias para algunos requests
  headers: any = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))});
  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onApiDataChange = new BehaviorSubject('');
  }

  /**
   * Get Notifications Data
   */
  // getNotificationsData(){
  //   return this._httpClient.get<any>(`${environment.apiBackend}/api/pagos-notificaciones`).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  /**
   * 
   * @param id @data
   * @returns 
   */  

  updateNotificationsData(id:any) {
    return this._httpClient.put<any>(`${environment.apiBackend}/api/actualiza-notificaciones/${id}`, this.headers);
  }

  private handleError(error:HttpErrorResponse) {
    if(error.status === 0) {
      console.error('Un error ha ocurrido:', error.error);
    } else {
      console.error(`Se ha encontrado un error en el servidor ${error.status}`, error.error);
    }
    return throwError(() => new Error('Algo salio mal por favor intentalo nuevamente'));
  }
}
