<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{
      lengthNotification
    }}</span></a
  >
  <ul ngbDropdownMenu aria-labelledby="navbarNotificationDropdown" class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto"> Notificationes </h4>
        <span class="badge badge-light-primary badge-pill"> Nuevas {{lengthNotification}} </span>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]> 
      <a class="d-flex" (click)="updateNotification()" *ngFor="let notification of notifications">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              
            >
              <div class="avatar-content"><i class="avatar-icon fa fa-money"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading">Proximo pago de: {{notification.name+' '+notification.lastname}}</p>
            <small class="notification-text">Consultora: {{notification.consultant}}</small>
            <br>
            <small class="notification-text">Vencen en: {{notification.date_payment}}</small>
          </div>
        </div></a>
    </li>

  </ul>
</li>
