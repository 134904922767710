import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  // {
  //   id: 'dashboard',
  //   title: 'Dashboard',
  //   translate: 'MENU.DASHBOARD.COLLAPSIBLE',
  //   type: 'collapsible',
  //   // role: ['Admin'], //? To hide collapsible based on user role
  //   icon: 'home',
  //   badge: {
  //     title: '2',
  //     translate: 'MENU.DASHBOARD.BADGE',
  //     classes: 'badge-light-warning badge-pill'
  //   },
  //   children: [
  //     {
  //       id: 'analytics',
  //       title: 'Analytics',
  //       translate: 'MENU.DASHBOARD.ANALYTICS',
  //       type: 'item',
  //       role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
  //       icon: 'circle',
  //       url: 'dashboard/analytics'
  //     },
  //     {
  //       // If role is not assigned will be display to all
  //       id: 'ecommerce',
  //       title: 'eCommerce',
  //       translate: 'MENU.DASHBOARD.ECOMMERCE',
  //       type: 'item',
  //       icon: 'circle',
  //       url: 'dashboard/ecommerce'
  //     }
  //   ]
  // },
  // Apps & Pages
  {
    id: 'apps',
    type: 'section',
    title: '',
    translate: 'MENU.APPS.SECTION',
    icon: 'package',
    children: [
      {
        // If role is not assigned will be display to all
        id: 'ecommerce',
        title: 'Inicio',
        type: 'item',
        icon: 'home',
        url: 'dashboard/ecommerce'
      },
      {
        id: 'generation',
        title: 'Generaciones',
        type: 'item',
        icon: 'book-open',
        url: 'apps/generacion'
      },
      {
        id: 'students',
        title: 'Clientes',
        type: 'item',
        icon: 'users',
        url: 'apps/clientes'
      },
      {
        id: 'accounts',
        title: 'Cuentas bancarias',
        type: 'item',
        icon: 'credit-card',
        url: 'apps/accounts'
      },
      {
        id: 'paymentRun',
        title: 'Corridas',
        type: 'item',
        icon: 'trending-up',
        url: 'apps/runs'
      }
    ]
  }
]
