import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const isLoggedIn = currentUser;
    // const isApiUrl = request.url.startsWith('https://api-cobranza.herokuapp.com/api');
    // const isApiUrl = request.url.startsWith('http://127.0.0.1:8000/api/');
    const isApiUrl = request.url.startsWith('https://juridicovalverde.net/backend/api/');
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          'Accept'       : 'application/json',
          'Authorization': `Bearer ${currentUser.token}`,
          'Access-Control-Allow-Headers': '*'
        }
      });
    }
    return next.handle(request);
  }
}
