import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor() {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //const currentUser = this._authenticationService.currentUserValue;
    //console.log(currentUser);
    //const isLoggedIn = currentUser;
    //const isApiUrl = request.url.startsWith(environment.apiBackend);
    // if (isLoggedIn) {
    //   console.log(isLoggedIn);
    //   console.log(request);
    //   request = request.clone({
    //     setHeaders: {
    //       'Content-Type' : 'application/json; charset=utf-8',
    //       'Accept'       : 'application/json',
    //       'Authorization': `Bearer ${currentUser}`
    //     }
    //   });
    // }

    return next.handle(request);
  }
}

export const JwtInterceptorProvider = [
  {provide:HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
]
